import { useState, type FC } from "react";
import { BsWhatsapp } from "react-icons/bs";
interface ContactProps {}

const Contact: FC<ContactProps> = ({}) => {
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const number = "6287776745468";
  return (
    <section
      id="contact"
      className=" mx-auto py-16"
      style={{
        backgroundImage:
          "url('https://images.unsplash.com/photo-1523966211575-eb4a01e7dd51?q=80&w=3710&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }}
    >
      <div className="max-w-lg sm:mx-auto bg-white p-8 rounded-xl shadow mx-8 ">
        <h2 className="text-3xl font-bold text-center mb-10 ">Contact Us</h2>
        <form>
          <div className="mb-4">
            <label className="block text-gray-700">Name</label>
            <input
              type="text"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-600"
              value={name}
              onChange={(val) => setName(val.target.value)}
              placeholder="Your Name"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Message</label>
            <textarea
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-600"
              rows={7}
              value={message}
              onChange={(val) => setMessage(val.target.value)}
              placeholder="Your Message"
              defaultValue={""}
            />
          </div>
          <button
            onClick={() => {
              window.open(`https://wa.me/${number}?text=Halo Nama Saya ${name}, ${message}
`);
            }}
            type="submit"
            className="w-full bg-green-600 text-white px-4 py-2 rounded-2xl mt-8 hover:bg-blue-700 flex justify-center items-center gap-2"
          >
            <BsWhatsapp className="text-white" />
            Send Message
          </button>
        </form>
      </div>
    </section>
  );
};
export default Contact;
