import type { FC } from "react";
import { Link } from "react-router-dom";

interface ClientProps {}

const Client: FC<ClientProps> = ({}) => {
  return (
    <section id="Client" className="bg-white py-20">
      <div className="container mx-auto text-center px-16 sm:px-48">
        <h2 className="text-3xl font-bold mb-12">Our Happy Client</h2>
        <div className="client-logo">
          <Link to={"https://jaraya.id/"} target="_blank" title="Jaraya app">
            <img src="/images/logo_jaraya.png" alt="" />
          </Link>
          <Link to={"https://www.niecindonesia.com/"} target="_blank" title="NIEC Indonesia">
            <img src="/images/logo-niec.png" alt="" />
          </Link>
          <Link to={""} target="_blank">
            <img src="/images/logo-focuson.png" alt="" title="PT. Focuson Siber Mediatama" />
          </Link>
          <Link to={""} target="_blank">
            <img src="/images/logo-sis.jpeg" alt="" title="PT. Sumapala Integrasi Solusi " />
          </Link>
          <Link to={""} target="_blank">
            <img src="/images/logo-bks.png" alt="" title="BKS" />
          </Link>
          <Link to={""} target="_blank">
            <img src="/images/logo-aditory.png" alt="" title="Aditory" />
          </Link>
          <Link to={"https://linktr.ee/balipetshopp/"} target="_blank" title="Bali Petshop">
            <img src="/images/logo-bali-petshop.jpg" alt="" />
          </Link>
        </div>
      </div>
    </section>
  );
};
export default Client;
