import type { FC } from "react";
import MainLayout from "../layouts/MainLayout";
import Hero from "../components/Hero";
import Feature from "../components/Feature";
import Pricing from "../components/Pricing";
import About from "../components/About";
import Contact from "../components/Contact";
import Client from "../components/Client";

interface HomeProps {}

const Home: FC<HomeProps> = ({}) => {
  return (
    <MainLayout>
      <Hero />
      <Feature />
      <About />
      <Client />
      <Contact />
    </MainLayout>
  );
};
export default Home;
