import { useState, type FC } from "react";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { Button, Drawer, Placeholder } from "rsuite";

interface HeaderProps {}

const Header: FC<HeaderProps> = ({}) => {
  const [open, setOpen] = useState(false);

  return (
    <nav className="bg-white p-6 shadow-lg">
      <div className="container mx-auto flex items-center justify-between">
        <div className="text-2xl font-bold text-gray-800">
          <a href="/">
            <img className="h-8" src="/images/logo araya.png" alt="" />
          </a>
        </div>
        <div className="hidden sm:block">
          <a href="/" className="text-gray-600 hover:text-gray-800 mx-4">
            Home
          </a>
          <a href="/privacy" className="text-gray-600 hover:text-gray-800 mx-4">
            Privacy Policy
          </a>
          <a
            href="/term-condition"
            className="text-gray-600 hover:text-gray-800 mx-4"
          >
            Term Condition
          </a>
          <a
            href="/#contact"
            className="text-gray-600 hover:text-gray-800 mx-4"
          >
            Contact
          </a>
        </div>
        <div className="hidden sm:block">
          <a
            href="https://admin.araya.web.id"
            className="bg-white text-blue-600 px-8 py-2 rounded-3xl  border-blue-600 border hover:bg-blue-600 hover:text-white"
          >
            Login
          </a>
        </div>
        <div className="sm:hidden">
          <HiOutlineMenuAlt3 onClick={() => setOpen(true)} />
        </div>
      </div>
      <Drawer
        size={"xs"}
        placement={"right"}
        open={open}
        onClose={() => setOpen(false)}
      >
        <Drawer.Header>
          <Drawer.Title>
            <a href="/">
              <img className="h-8" src="/images/logo araya.png" alt="" />
            </a>
          </Drawer.Title>
        </Drawer.Header>
        <Drawer.Body style={{padding: 20}}>
          <div className="flex flex-col gap-4">
            <a href="/" className="text-gray-600 hover:text-gray-800 mx-4">
              Home
            </a>
            <a
              href="/privacy"
              className="text-gray-600 hover:text-gray-800 mx-4"
            >
              Privacy Policy
            </a>
            <a
              href="/term-condition"
              className="text-gray-600 hover:text-gray-800 mx-4"
            >
              Term Condition
            </a>
            <a
              href="/#contact"
              className="text-gray-600 hover:text-gray-800 mx-4"
            >
              Contact
            </a>
            <a
              href="https://admin.araya.web.id"
              className="text-gray-600 hover:text-gray-800 mx-4"
            >
              Login
            </a>
          </div>
        </Drawer.Body>
      </Drawer>
    </nav>
  );
};
export default Header;
