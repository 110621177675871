import type { FC } from "react";
import MainLayout from "../layouts/MainLayout";

interface PrivacyProps {}

const Privacy: FC<PrivacyProps> = ({}) => {
  return (
    <MainLayout>
      <section className="py-12 bg-white">
        <div className="container mx-auto px-16 sm:px-60">
          <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
          <small className="mb-8">Latest Update Aug, 20 2024</small>
          <p className="mb-4">
            <strong>1. Introduction</strong>
            <br />
            Welcome to Araya. We value your privacy and are committed to
            protecting your personal data. This Privacy Policy outlines how we
            collect, use, and safeguard your information when you use our
            services.
          </p>
          <p className="mb-4">
            <strong>2. Data Collection</strong>
            <br />
            We collect personal information that you provide directly to us,
            such as when you register for an account, fill out a form, or
            communicate with us. This may include your name, email address,
            phone number, and any other details required to fulfill our
            services.
          </p>
          <p className="mb-4">
            <strong>3. Use of Information</strong>
            <br />
            The information we collect is used to operate and improve our
            services, provide customer support, communicate with you about
            updates and promotions, and ensure compliance with legal
            obligations.
          </p>
          <p className="mb-4">
            <strong>4. Data Security</strong>
            <br />
            We take data security seriously and implement appropriate measures
            to protect your information from unauthorized access, alteration, or
            destruction. However, please note that no method of transmission
            over the internet is 100% secure.
          </p>
          <p className="mb-4">
            <strong>5. Sharing of Information</strong>
            <br />
            We do not share your personal information with third parties except
            as required by law or with your explicit consent. We may share
            non-personally identifiable information for analytics and marketing
            purposes.
          </p>
          <p className="mb-4">
            <strong>6. Your Rights</strong>
            <br />
            You have the right to access, correct, or delete your personal data
            at any time. You may also object to the processing of your data or
            request the restriction of its use. To exercise these rights, please
            contact our support team.
          </p>
          <p className="mb-4">
            <strong>7. Changes to This Policy</strong>
            <br />
            We may update this Privacy Policy from time to time to reflect
            changes in our practices or legal requirements. We encourage you to
            review this policy periodically.
          </p>
          <p className="mb-4">
            <strong>8. Contact Us</strong>
            <br />
            If you have any questions or concerns about this Privacy Policy,
            please contact us at support@araya.com.
          </p>
        </div>
      </section>
    </MainLayout>
  );
};
export default Privacy;
