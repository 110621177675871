import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Privacy from "./pages/Privacy";
import Term from "./pages/Term";
import TermPage from "./pages/Term";
import 'rsuite/dist/rsuite-no-reset.min.css';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/term-condition" element={<TermPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
