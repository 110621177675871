import type { FC } from "react";

interface FooterProps {}

const Footer: FC<FooterProps> = ({}) => {
  return (
    <footer className="bg-gray-100 px-12 py-6 sm:px-24">
      <div className="container mx-auto text-center text-gray-600">
        <p>© 2024 PT. Jayalah Rakyat Indonesia. All rights reserved.</p>
      </div>
    </footer>
  );
};
export default Footer;
