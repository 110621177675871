import type { FC } from "react";

interface HeroProps {}

const Hero: FC<HeroProps> = ({}) => {
  return (
    <header
      className="bg-blue-600 text-white"
      style={{
        backgroundImage: `url('https://plus.unsplash.com/premium_photo-1682310140123-d479f37e2c88?q=80&w=3612&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }}
    >
      <div className="container mx-auto flex flex-col items-center sm:px-24 px-16 py-20">
        <h1 className="text-4xl font-bold">
          Manage Your Workforce Efficiently with Araya
        </h1>
        <p className="mt-4 text-lg">
          The all-in-one HRIS solution to streamline employee management.
        </p>
        <a
          href="#contact"
            className="bg-white text-blue-500 px-6 py-3 mt-8 rounded-full font-semibold hover:bg-gray-200"
        >
          Get Started
        </a>
      </div>
    </header>

  );
};
export default Hero;
