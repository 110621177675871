import type { FC } from "react";

interface AboutProps {}

const About: FC<AboutProps> = ({}) => {
  return (
    <section id="about" className="bg-gray-50 py-20">
      <div className="container mx-auto text-center px-16 sm:px-48">
        <h2 className="text-3xl font-bold mb-12">About Araya</h2>
        <p className="text-normal mb-8">
          Araya is more than just a Human Resource Information System; it's a
          comprehensive solution designed to revolutionize how companies manage
          their workforce. From employee onboarding to performance evaluations,
          Araya simplifies every aspect of HR management, allowing you to focus
          on what truly matters—growing your business. Our intuitive platform is
          built with the latest technology to ensure a seamless user experience,
          even for those with minimal technical expertise. 
        </p>
        <p className="text-normal mb-8">
        With Araya, you can
          automate mundane HR tasks, reduce errors, and improve overall
          efficiency. Join countless companies that have transformed their HR
          operations with Araya, and take the first step towards a more
          organized and productive workplace.
        </p>
      </div>
    </section>
  );
};
export default About;
