import type { FC } from "react";
import { PiHandHeart } from "react-icons/pi";

interface FeatureProps {}

const Feature: FC<FeatureProps> = ({}) => {
  return (
    <section id="features" className="py-20  sm:bg-white bg-gray-400">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-bold text-center text-gray-900">
          Features
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-10 mt-10">
          <div className="bg-white p-6 rounded-lg hover:shadow-lg shadow h-[300px] flex justify-center flex-col text-center sm:mb-0 mb-8">
            <div className="text-5xl text-blue-600 mb-4 flex justify-center">
              <img src="/images/division.png" alt="" className="w-16" />
              {/* Replace with actual react-icons component */}
              <i className="ri-user-3-line" />
            </div>
            <h3 className="text-2xl font-semibold text-gray-800">
              Employee Management
            </h3>
            <p className="mt-2 text-gray-600">
              Easily manage employee data, track performance, and handle
              payroll.
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg hover:shadow-lg shadow h-[300px] flex justify-center flex-col text-center sm:mb-0 mb-8">
            <div className="text-5xl text-blue-600 mb-4 flex justify-center">
              <img src="/images/delivery.png" alt="" className="w-16" />
              {/* Replace with actual react-icons component */}
              <i className="ri-calendar-check-line" />
            </div>
            <h3 className="text-2xl font-semibold text-gray-800">
              Attendance Tracking
            </h3>
            <p className="mt-2 text-gray-600">
              Track attendance with ease, including shift scheduling and leave
              management.
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg hover:shadow-lg shadow h-[300px] flex justify-center flex-col text-center sm:mb-0 mb-8">
            <div className="text-5xl text-blue-600 mb-4 flex justify-center">
              <img src="/images/calendar.png" alt="" className="w-16" />
              {/* Replace with actual react-icons component */}
              <i className="ri-notification-3-line" />
            </div>
            <h3 className="text-2xl font-semibold text-gray-800">
              Payroll Automation
            </h3>
            <p className="mt-2 text-gray-600">
              Automate payroll processing and reduce manual errors.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Feature;
