import type { FC } from "react";
import MainLayout from "../layouts/MainLayout";

interface TermPageProps {}

const TermPage: FC<TermPageProps> = ({}) => {
  return (
    <MainLayout>
      <section className="py-12 bg-gray-50">
        <div className="container mx-auto px-16 sm:px-60">
          <h1 className="text-3xl font-bold mb-4">Terms &amp; Conditions</h1>
          <small className="mb-8">Latest Update Aug, 20 2024</small>
          <p className="mb-4">
            <strong>1. Introduction</strong>
            <br />
            These Terms &amp; Conditions ("Terms") govern your access to and use
            of Araya's services. By using our services, you agree to comply with
            and be bound by these Terms.
          </p>
          <p className="mb-4">
            <strong>2. Use of Services</strong>
            <br />
            You may use our services only in compliance with these Terms and all
            applicable laws. You agree not to misuse our services, including by
            interfering with their normal operation or attempting to access them
            using a method other than the interface and instructions provided.
          </p>
          <p className="mb-4">
            <strong>3. Account Registration</strong>
            <br />
            To use certain features of our services, you may be required to
            register an account. You agree to provide accurate, current, and
            complete information during the registration process and to update
            such information as necessary.
          </p>
          <p className="mb-4">
            <strong>4. User Responsibilities</strong>
            <br />
            You are responsible for maintaining the confidentiality of your
            account credentials and for all activities that occur under your
            account. You agree to notify us immediately of any unauthorized use
            of your account.
          </p>
          <p className="mb-4">
            <strong>5. Termination</strong>
            <br />
            We reserve the right to suspend or terminate your access to our
            services at any time, without notice, for conduct that we believe
            violates these Terms or is harmful to other users or our business
            interests.
          </p>
          <p className="mb-4">
            <strong>6. Intellectual Property</strong>
            <br />
            All content, trademarks, and data on this site, including but not
            limited to software, text, graphics, and logos, are the property of
            Araya or its licensors. You may not reproduce, distribute, or create
            derivative works based on our content without our express written
            permission.
          </p>
          <p className="mb-4">
            <strong>7. Limitation of Liability</strong>
            <br />
            To the fullest extent permitted by law, Araya shall not be liable
            for any indirect, incidental, special, or consequential damages
            arising out of or in connection with your use of our services.
          </p>
          <p className="mb-4">
            <strong>8. Governing Law</strong>
            <br />
            These Terms shall be governed by and construed in accordance with
            the laws of [Your Country/State], without regard to its conflict of
            law principles.
          </p>
          <p className="mb-4">
            <strong>9. Changes to These Terms</strong>
            <br />
            We may modify these Terms at any time by posting the revised version
            on our website. Your continued use of our services after such
            changes constitutes your acceptance of the new Terms.
          </p>
          <p className="mb-4">
            <strong>10. Contact Us</strong>
            <br />
            If you have any questions about these Terms, please contact us at
            support@araya.com.
          </p>
        </div>
      </section>
    </MainLayout>
  );
};
export default TermPage;
